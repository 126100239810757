<template>
  <div class="inner-section">
    <card>
      <!-- search section start -->
      <template v-slot:searchHeaderTitle>
        <h4 class="card-title">{{ $t('teaGardenPduService.garden_inspection_report') }}</h4>
      </template>
      <template v-slot:searchBody>
        <b-row>
          <!-- Garden list -->
          <b-col xs="12" sm="12" md="4" lg="4" xl="4">
            <ValidationProvider name="Garden Name" vid="garden_id">
              <b-form-group
                  label-for="garden_id"
                  slot-scope="{ valid, errors }"
                  >
                  <template v-slot:label>
                  {{ $t('teaGardenPduService.garden_name') }}
                  </template>
                  <b-form-select
                  plain
                  v-model="search.garden_id"
                  :options="gardenList"
                  id="garden_id"
                  :state="errors[0] ? false : (valid ? true : null)"
                  >
                  <template v-slot:first>
                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                  </b-form-select>
                  <div class="invalid-feedback">
                  {{ errors[0] }}
                  </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
            <b-col xs="12" sm="12" md="4" lg="4" xl="4">
              <b-form-group label-for="visit_date">
                <template v-slot:label>
                  {{ $t('teaGardenBtriService.visit_date') }}
                </template>
                <date-picker
                  id="visit_date"
                  class="form-control"
                  v-model="search.visit_date"
                  :placeholder="$t('globalTrans.select')"
                >
                </date-picker>
              </b-form-group>
          </b-col>
          <b-col xs="12" sm="12" md="4" lg="4" xl="4">
            <b-button size="sm" variant="primary" class="mt-20" @click="searchData">
              <i class="ri-search-line"></i> {{ $t('globalTrans.search') }}
            </b-button>
          </b-col>
        </b-row>
      </template>
      <!-- search section end -->
    </card>

    <b-row>
      <b-col md="12" v-if="showData">
        <body-card>
              <!-- table section start -->
              <template v-slot:headerTitle>
                <h4 class="card-title">{{ $t('teaGardenPduService.garden_inspection_report') }}</h4>
              </template>
              <template v-slot:headerAction>
                <a href="javascript:" class="btn-add" @click="pdfExport">
                   {{ $t('globalTrans.export_pdf') }}
                </a>
              </template>
              <template v-slot:body>
                <b-overlay :show="loadingState">
                  <b-row>
                    <b-col>
                      <list-report-head :base-url="teaGardenServiceBaseUrl" uri="/configuration/report-heading/detail" :org-id="5" :service-id="17">
                        <template v-slot:projectNameSlot>
                          {{ }}
                        </template>
                        {{ $t('teaGardenPduService.garden_inspection_report') }}
                      </list-report-head>
                    </b-col>
                  </b-row>
                  <template>
                    <div class="text-black mb-4">
                      <b-row>
                        <b-col md="8">
                          {{ $t('teaGardenPduService.garden_name') }}: <strong>{{ search.garden_id ? ($i18n.locale === 'en' ? searchHeaderData.garden_name_en : searchHeaderData.garden_name_bn) : $t('globalTrans.all') }}</strong>
                        </b-col>
                        <b-col md="4">
                          {{ $t('teaGardenBtriService.visit_date') }}: <strong>{{ search.visit_date ? search.visit_date : $t('globalTrans.all') }}</strong>
                        </b-col>
                      </b-row>
                    </div>
                    <div>
                      <div class="table-responsive">
                        <b-table-simple class="tg mt-3" bordered striped hover small caption-top responsive :emptyText="$t('globalTrans.noDataFound')">
                          <b-thead>
                            <b-tr>
                              <b-th class="text-center">{{ $t('globalTrans.sl_no') }}</b-th>
                              <b-th class="text-center">{{ $t('teaGardenPduService.garden_name') }}</b-th>
                              <b-th class="text-center">{{ $t('teaGardenPduService.visit_time_dele_name') }}</b-th>
                              <b-th class="text-center">{{ $t('teaGardenPduService.delegate_mobile_no') }}</b-th>
                              <b-th class="text-center">{{ $t('teaGardenBtriService.tentative_date') }}</b-th>
                              <b-th class="text-center">{{ $t('teaGardenBtriService.visit_date') }}</b-th>
                              <b-th class="text-center">{{ $t('teaGardenPduService.report_submission_date') }}</b-th>
                            </b-tr>
                          </b-thead>
                          <b-tbody>
                            <b-tr v-for="(item, index) in datas" :key="index">
                              <b-td class="text-center">{{ $n(index + 1) }}</b-td>
                              <b-td class="text-center">{{ getGardenName(item.garden_id) }}</b-td>
                              <b-td class="text-center">{{ currentLocale == 'en' ? item.visit_time_del_name_en : item.visit_time_del_name_bn }}</b-td>
                              <b-td class="text-center">{{ item.delegate_mobile_no | mobileNumber }}</b-td>
                              <b-td class="text-center">{{ item.detail.tentative_date | dateFormat }}</b-td>
                              <b-td class="text-center">{{ item.visit_date | dateFormat }}</b-td>
                              <b-td class="text-center">{{ item.created_at | dateFormat }}</b-td>
                            </b-tr>
                            <b-tr v-if="Object.keys(datas).length === 0">
                              <th colspan="7" class="text-center">{{ $t('globalTrans.no_data_found') }}</th>
                            </b-tr>
                          </b-tbody>
                        </b-table-simple>
                      </div>
                    </div>
                  </template>
                </b-overlay>
              </template>
              <!-- table section end -->
        </body-card>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import RestApi, { teaGardenServiceBaseUrl } from '@/config/api_config'
import { pduGardenInspectionApi } from '../../../api/routes'
import ModalBaseMasterList from '@/mixins/list'
import ListReportHead from '@/components/custom/TeaGardenReportHead.vue'
import excel from 'vue-excel-export'
import Vue from 'vue'
Vue.use(excel)

export default {
  mixins: [ModalBaseMasterList],
  components: {
    ListReportHead
  },
  data () {
    return {
      teaGardenServiceBaseUrl: teaGardenServiceBaseUrl,
      sortBy: '',
      search: {
        garden_id: 0,
        visit_date: 0,
        limit: 20
      },
      editItemId: '',
      sortDesc: true,
      sortDirection: 'desc',
      datas: [],
      searchHeaderData: {
        garden_name_en: '',
        garden_name_bn: ''
      },
      showData: false
    }
  },
  created () {
  },
  watch: {
    'search.limit': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.loadData()
      }
    }
  },
  computed: {
    gardenList () {
      return this.$store.state.TeaGardenService.commonObj.masterTeaGardenGenInfoList.filter(item => item.status === 1)
    },
    formTitle () {
      return (this.editItemId === 0) ? this.$t('priceMonitoring.market_inspection_report') + ' ' + this.$t('globalTrans.entry') : this.$t('priceMonitoring.market_inspection_report') + ' ' + this.$t('globalTrans.modify')
    },
    currentLocale () {
      return this.$i18n.locale
    }
  },
  methods: {
    async pdfExport () {
      const params = Object.assign({ request_type: 'pdf', local: this.$i18n.locale, org_id: 5 }, this.search)
      const service = this.$store.state.TeaGardenService.commonObj.masterServiceList.find(item => item.value === 28)
      if (service !== undefined) {
        if (service.office_type_id) {
          params.office_type_id = service.office_type_id
        }
        if (service.office_id) {
          params.office_id = service.office_id
        }
      }
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      const result = await RestApi.getPdfData(teaGardenServiceBaseUrl, pduGardenInspectionApi, params)
      var blob = new Blob([result], {
        type: 'application/pdf'
      })
      var url = window.URL.createObjectURL(blob)
      window.open(url).print()
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    },
    searchData () {
      this.loadData()
    },
    async loadData () {
      if (this.search.garden_id) {
        const obj = this.$store.state.TeaGardenService.commonObj.masterTeaGardenGenInfoList.find(data => data.value === this.search.garden_id)
        this.searchHeaderData.garden_name_en = obj !== undefined ? obj.text_en : ''
        this.searchHeaderData.garden_name_bn = obj !== undefined ? obj.text_bn : ''
      }
      this.showData = true
      const params = Object.assign({}, this.search)
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      const result = await RestApi.getData(teaGardenServiceBaseUrl, pduGardenInspectionApi, params)
      if (result.success) {
        this.datas = result.data
        this.$store.dispatch('setList', this.datas)
      } else {
        this.$store.dispatch('setList', [])
      }
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    },
    getGardenName (id) {
      const obj = this.$store.state.TeaGardenService.commonObj.masterTeaGardenGenInfoList.find(data => data.value === id)
        if (obj !== undefined) {
          if (this.$i18n.locale === 'bn') {
              return obj.text_bn
          } else {
              return obj.text_en
          }
       }
    }
  }
}
</script>
